body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #141414;
  color: white;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.header, .divider, .engagement-text {
  min-width: 720px;
  max-width: 720px;
}

.video-player, .player-image {
  min-height: 360px;
  max-height: 360px;
}

.video-player, .player-image, .player-overlay-top, .player-overlay-bottom {
  min-width: 720px;
  max-width: 720px;
}

.player-overlay-top {
  height: 60px;
}

.player-overlay-bottom {
  height: 43px;
  margin-top: 319px;
}

.guess-input {
  min-width: 680px;
  max-width: 680px;
}

.MuiPaper-root {
  max-height: 160px;
}

.footer {
  margin-bottom: 50px;
}

@media screen and (max-width: 600px) {
  .header, .divider, .engagement-text {
    min-width: 340px;
    max-width: 340px;
  }

  .video-player, .player-image {
    min-height: 200px;
    max-height: 200px;
  }

  .video-player, .player-image, .player-overlay-top, .player-overlay-bottom {
    min-width: 340px;
    max-width: 340px;
  }

  .player-overlay-top {
    height: 55px;
  }
  
  .player-overlay-bottom {
    height: 37px;
    margin-top: 164px;
  }

  .guess-input {
    min-width: 300px;
    max-width: 300px;
  }

  .footer {
    margin-bottom: 10px;
  }
}